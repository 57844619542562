:root {
  --h_ads_player_sticky_width: 373px;
  --h_ads_player_sticky_height: 201px;
  --v_player_sticky_width: 120px;
  --v_player_sticky_height: 213px;
  --v_player_sticky_top: 30px;
  --v_player_sticky_left: 15px;
}

/* equal tailwind sm breakpoint */
@media screen and (max-width: 480px) {
  .jw-flag-mini-sticky:not(.customized_sticky) {
    --small-sticky-height: 88px;

    .jw-flag-floating {
      .jw-wrapper {
        width: 100% !important;
        max-width: none !important;
        margin-top: 0 !important;
      }
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-wrapper {
      height: var(--small-sticky-height);
      z-index: 1001 !important;
      max-height: 200px;
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-video,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-preview,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-overlays {
      margin: 0;
      width: 40%;
      object-fit: contain;
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-controls,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-controls-backdrop {
      left: 40%;
      width: 60%;
      background: #222;
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-button-container,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-float-icon,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-title {
      display: flex !important;
      justify-content: flex-end;
      padding-top: 12px;
      padding-right: 0 !important;
    }
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-title .jw-title-secondary {
      display: none;
    }
    /* FIXME: What is this strange css class .css-vkjmnj? */
    .css-vkjmnj .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-float-icon.jw-icon {
      right: 0px;
      margin-right: 10px;
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-text-elapsed,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-text-countdown,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-text-duration,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-display,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-spacer,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-slider-time,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-settings-sharing,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-icon-settings,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-icon-fullscreen,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-horizontal-volume-container,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-related-btn,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-icon-fullscreen.jw-fullscreen-ima,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-slider-volume {
      display: none !important;
    }

    /* FIXME: What is this strange css class .css-vkjmnj? */
    .css-vkjmnj .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-button-container {
      justify-content: flex-start;
      padding: 0px;
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-button-container {
      padding: 0 16px 8px 0;
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-controls,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-controlbar,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-float-icon,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-icon-playback,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-icon-fullscreen,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-button-container,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-controls-backdrop {
      opacity: 1 !important;
      display: flex !important;
      transform: none !important;
      visibility: visible !important;
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-icon {
      width: 32px;
      color: #fff;
      cursor: pointer;
      pointer-events: all;
      justify-content: flex-end;
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-title-primary::before {
      content: 'Nu spelas: ';
      font-size: 14px;
      font-weight: 100;
      white-space: normal;
      line-height: 23px;
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-title-primary {
      font-weight: bold;
      padding-left: 42%;
      font-size: 14px;
      padding-right: 33px;
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-float-icon.jw-icon {
      right: 0;
      margin-right: 8px;
      top: -3px;
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-button-container {
      justify-content: flex-start;
      padding: 0;
    }

    .jw-flag-floating.jw-flag-floating.jw-flag-ads .jw-aspect.jw-reset {
      /* set max size for the ads */
      padding-top: 225px !important;
    }
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-aspect.jw-reset {
      padding-top: var(--small-sticky-height) !important;
    }

    .jw-flag-small-player .jw-nextup-container .jw-nextup.jw-nextup-thumbnail-visible {
      display: none;
    }
    .jw-flag-floating .jw-ads-view > div,
    .jw-flag-floating .jw-ads-view iframe {
      height: 100% !important;
    }
    .jw-flag-floating.jw-floating-dismissible .jw-wrapper.jw-float-to-top.jw-reset,
    .jw-flag-floating.jw-floating-dismissible .jw-wrapper.jw-reset {
      top: 0;
      bottom: unset;
      animation: none;
      max-height: 237px;
    }
  }
}

.customized_sticky {
  .jw-flag-floating.jw-floating-dismissible .jw-icon-rewind,
  .jw-flag-floating.jw-floating-dismissible .jw-text-elapsed,
  .jw-flag-floating.jw-floating-dismissible .jw-related-btn {
    visibility: hidden;
  }

  /* equal tailwind sm breakpoint */
  @media screen and (max-width: 480px) {
    .jw-flag-floating.jw-floating-dismissible .jw-wrapper.jw-float-to-top.jw-reset,
    .jw-flag-floating.jw-floating-dismissible .jw-wrapper.jw-reset {
      top: 0;
      bottom: unset;
      max-height: var(--h_ads_player_sticky_height);
      max-width: var(--h_ads_player_sticky_width);
      top: var(--v_player_sticky_top);
    }
    .jw-flag-floating.jw-flag-floating .jw-aspect.jw-reset {
      padding-top: var(--h_ads_player_sticky_height) !important;
    }

    .jw-flag-floating.jw-flag-ads .jw-aspect.jw-reset {
      transform: translate(0px) !important;
    }

    /* New horizontal player variant */
    &.h_player {
      .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-aspect.jw-reset {
        padding-top: var(--v_player_sticky_width) !important;
      }

      .jw-flag-floating:not(.jw-flag-ads).jw-floating-dismissible .jw-wrapper.jw-float-to-top.jw-reset,
      .jw-flag-floating:not(.jw-flag-ads).jw-floating-dismissible .jw-wrapper.jw-reset {
        bottom: unset;
        animation: none;
        top: var(--v_player_sticky_top) !important;
        max-width: var(--v_player_sticky_height) !important;
        max-height: var(--v_player_sticky_width) !important;
        left: var(--v_player_sticky_left);
        margin: 0;
      }
    }

    /* New vertical player variant */
    &.v_player {
      .jw-button-container {
        z-index: 10;
      }
      .jwplayer.jw-flag-aspect-mode {
        height: 100% !important;
      }

      .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-wrapper.jw-float-to-top.jw-reset,
      .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-wrapper.jw-reset {
        bottom: unset;
        animation: none;
        top: var(--v_player_sticky_top) !important;
        max-height: var(--v_player_sticky_height) !important;
        max-width: var(--v_player_sticky_width) !important;
        left: var(--v_player_sticky_left);
        margin: 0;
      }

      /* Styles for play/pause icon */
      .jw-flag-floating.jw-floating-dismissible:not(.jw-flag-ads) .jw-icon.jw-icon-display.jw-button-color.jw-reset {
        width: 40px !important;
        height: 28px !important;
        bottom: 7px;
      }

      .jw-icon.jw-icon-inline.jw-button-color.jw-reset.jw-icon-volume {
        bottom: 0px;
      }
    }
  }
}
