html {
  @apply h-full;
}

body {
  @apply !overflow-x-hidden;
  -webkit-font-smoothing: antialiased;
}

.hide-in-mobile-app {
  /* dirty workaround 
  use this class to hide elements inside mobile app,
  styles for this class comes from cloudflare worker
  keep it empty */
}

#onetrust-policy .ot-dpd-container .ot-dpd-content ul,
#onetrust-policy .ot-dpd-container .ot-dpd-content button {
  font-size: 0.813rem;
}
#onetrust-policy .ot-dpd-container .ot-dpd-content ul li {
  line-height: 1.5;
}

.grecaptcha-badge {
  visibility: hidden;
}
