.lab {
  --lab-bg: initial;
  --lab-color: initial;
  --lab-font: initial;
  --lab-size: initial;
  --lab-weight: initial;
  --lab-leading: initial;
  --lab-mb: initial;
  --lab-ml: initial;
  --lab-mr: initial;
  --lab-mt: initial;
  --lab-pb: initial;
  --lab-pl: initial;
  --lab-pr: initial;
  --lab-pt: initial;
  --lab-align: initial;

  background: var(--lab-bg);
  color: var(--lab-color);
  font-family: var(--lab-font);
  font-size: var(--lab-size);
  font-weight: var(--lab-weight);
  line-height: var(--lab-leading);
  margin-bottom: var(--lab-mb);
  margin-left: var(--lab-ml);
  margin-right: var(--lab-mr);
  margin-top: var(--lab-mt);
  padding-bottom: var(--lab-pb);
  padding-left: var(--lab-pl);
  padding-right: var(--lab-pr);
  padding-top: var(--lab-pt);
  text-align: var(--lab-align);
}

@media screen(sm) {
  .lab {
    --lab-sm-bg: initial;
    --lab-sm-color: initial;
    --lab-sm-font: initial;
    --lab-sm-size: initial;
    --lab-sm-weight: initial;
    --lab-sm-leading: initial;
    --lab-sm-mb: initial;
    --lab-sm-ml: initial;
    --lab-sm-mr: initial;
    --lab-sm-mt: initial;
    --lab-sm-pb: initial;
    --lab-sm-pl: initial;
    --lab-sm-pr: initial;
    --lab-sm-pt: initial;
    --lab-sm-align: initial;

    background: var(--lab-sm-bg, var(--lab-bg));
    color: var(--lab-sm-color, var(--lab-color));
    font-family: var(--lab-sm-font, var(--lab-font));
    font-size: var(--lab-sm-size, var(--lab-size));
    font-weight: var(--lab-sm-weight, var(--lab-weight));
    line-height: var(--lab-sm-leading, var(--lab-leading));
    margin-bottom: var(--lab-sm-mb, var(--lab-mb));
    margin-left: var(--lab-sm-ml, var(--lab-ml));
    margin-right: var(--lab-sm-mr, var(--lab-mr));
    margin-top: var(--lab-sm-mt, var(--lab-mt));
    padding-bottom: var(--lab-sm-pb, var(--lab-pb));
    padding-left: var(--lab-sm-pl, var(--lab-pl));
    padding-right: var(--lab-sm-pr, var(--lab-pr));
    padding-top: var(--lab-sm-pt, var(--lab-pt));
    text-align: var(--lab-sm-align, var(--lab-align));
  }
}

/* Labrador 4 classes */
.strikethrough {
  @apply !line-through;
}

.font-weight-black {
  @apply !font-black;
}

.font-weight-bold,
.bold {
  @apply !font-bold;
}

.font-weight-light {
  @apply !font-light;
}

.font-weight-normal {
  @apply !font-normal;
}
