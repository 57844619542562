@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'global.css';
@import 'labrador.css';
@import 'jwplayer/base.css';
@import 'ads/base.css';

@import '../../modules/dynamic/global/styles.css';
