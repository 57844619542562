#jw-vertical-video .navigation-icon {
  border-color: #000;
}
#jw-vertical-video .jw-media.jw-reset {
  cursor: pointer;
}
#jw-vertical-video-overlay .mobile-container {
  font-size: 0px !important;
}
#jw-vertical-video-overlay .mobile-container::after {
  content: 'Svep uppåt för nästa video';
  font-size: 16px;
  bottom: 14px;
  position: absolute;
  left: 25%;
}
