.bg-petroleum,
.bg-korall,
.bg-antik,
.bg-solros,
.bg-rose,
.bg-hostanemon,
.bg-al-petroleum-100,
.bg-al-petroleum-600,
.bg-al-korall-100,
.bg-al-korall-600,
.bg-al-antik-600,
.bg-al-solros-500,
.bg-al-solros-600,
.bg-al-rose,
.bg-al-hostanemon {
  @apply bg-radical-red-300;
}

.bg-al-aquamarine-300 {
  @apply bg-aquamarine-300;
}

.sm\:bg-al-aquamarine-300 {
  @apply sm:bg-aquamarine-300;
}

.wings-al-aquamarine-300 {
  @apply wings-aquamarine-300;
}

.sm\:wings-al-aquamarine-300 {
  @apply sm:wings-aquamarine-300;
}

.bg-al-dodger-blue-300 {
  @apply bg-dodger-blue-300;
}

.sm\:bg-al-dodger-blue-300 {
  @apply sm:bg-dodger-blue-300;
}

.wings-al-dodger-blue-300 {
  @apply wings-dodger-blue-300;
}

.sm\:wings-al-dodger-blue-300 {
  @apply sm:wings-dodger-blue-300;
}

.bg-al-dodger-blue-400 {
  @apply bg-dodger-blue-400;
}

.sm\:bg-al-dodger-blue-400 {
  @apply sm:bg-dodger-blue-400;
}

.wings-al-dodger-blue-400 {
  @apply wings-dodger-blue-400;
}

.sm\:wings-al-dodger-blue-400 {
  @apply sm:wings-dodger-blue-400;
}

.bg-al-husfarg-300 {
  @apply bg-husfarg-300;
}

.sm\:bg-al-husfarg-300 {
  @apply sm:bg-husfarg-300;
}

.wings-al-husfarg-300 {
  @apply wings-husfarg-300;
}

.sm\:wings-al-husfarg-300 {
  @apply sm:wings-husfarg-300;
}

.bg-al-logga-300 {
  @apply bg-logga-300;
}

.sm\:bg-al-logga-300 {
  @apply sm:bg-logga-300;
}

.wings-al-logga-300 {
  @apply wings-logga-300;
}

.sm\:wings-al-logga-300 {
  @apply sm:wings-logga-300;
}

.bg-al-logga-800 {
  @apply bg-logga-800;
}

.sm\:bg-al-logga-800 {
  @apply sm:bg-logga-800;
}

.wings-al-logga-800 {
  @apply wings-logga-800;
}

.sm\:wings-al-logga-800 {
  @apply sm:wings-logga-800;
}

.bg-al-radical-red-300 {
  @apply bg-radical-red-300;
}

.sm\:bg-al-radical-red-300 {
  @apply sm:bg-radical-red-300;
}

.wings-al-radical-red-300 {
  @apply wings-radical-red-300;
}

.sm\:wings-al-radical-red-300 {
  @apply sm:wings-radical-red-300;
}

.bg-al-yellow-600 {
  @apply bg-yellow-600;
}

.sm\:bg-al-yellow-600 {
  @apply sm:bg-yellow-600;
}

.wings-al-yellow-600 {
  @apply wings-yellow-600;
}

.sm\:wings-al-yellow-600 {
  @apply sm:wings-yellow-600;
}

.text-al-logga-300 {
  @apply text-logga-300;
}

.sm\:text-al-logga-300 {
  @apply sm:text-logga-300;
}

.text-al-dodger-blue-600 {
  @apply text-dodger-blue-600;
}

.sm\:text-al-dodger-blue-600 {
  @apply sm:text-dodger-blue-600;
}
